import { useRouteLoaderData } from '@remix-run/react'

import type { loader as rootLoader } from '~/routes/_root._authorized._main/route'

export const useRootLoaderData = () => {
  const rootLoaderData = useRouteLoaderData<typeof rootLoader>(
    'routes/_root._authorized._main',
  )

  return rootLoaderData
}
